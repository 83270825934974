import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Center, Flex, useColorMode } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import UserAvatar from "../../../Components/Avatar/Web";

import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";
import { isExternal } from "util/types";
import { isInTeams } from "../../../../Lib/Utils/routes";

const FormWrapper = ({
  displayGoBack,
  isProvider,
  children,
}: {
  displayGoBack?: boolean;
  isProvider?: boolean;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const searchParams = new URLSearchParams(window.location.search);

  const { colorMode, toggleColorMode } = useColorMode()

  const isAccess = searchParams.get("isAccess") === "true";

  const goBack = () => {

    if (isProvider) {
      return window.parent.postMessage(
        { command: "refresh" },
        "*"
      );
    }
        
    navigate("/dashboard");

  }

  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        w={["full", "full", "800px"]}
        h={["calc(100vh)", "calc(100vh)", "full"]}
        bgColor={colorMode === 'light' ? CLR_PANEL_BG : CLR_PANEL_BG_DARK}
        flexDirection="column"
        padding={isInTeams() ? "0px" : "23px"}
        pb={0}
        borderRadius={8}
        borderWidth={[0, 0, 1]}
        overflow="auto"        
      >
        <Flex>
          <Flex flex={1}>
            {(displayGoBack && !isAccess) && (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={goBack}
                  >
                    <FontAwesomeIcon
                      icon={faLongArrowLeft}
                      size={"xl"}
                      color={"#4190C3"}
                    />
                  </a>
                )}
          </Flex>          
            {!isAccess && <Flex flex={1} justifyContent={'flex-end'}>
                <UserAvatar disableOptionalItems={isProvider} />
              </Flex>}
        </Flex>        
        {children}
      </Flex>
    </Center>
  );
};

export default FormWrapper;
